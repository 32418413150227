import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, FigureDouble, List,
} from '../../components/opComponents';

const FractureDuPoignet = () => (
  <Layout
    title="Fracture du poignet : Diagnostic et traitement"
    description="La fracture du poignet est la fracture la plus fréquente du membre supérieur. Elle concerne principalement l'extrémité inférieure du radius."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-la-main" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de la main</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Fracture du poignet" />
        <MainPart title="Comment est constitué le poignet ?">
          <Typography>
            Le poignet est une articulation qui permet d&apos;établir la connexion entre
            l&apos;avant-bras et la main. Sur le plan osseux, il est constitué en deux
            parties (figure 1) :
            <List>
              <List.Item>
                <Typography>
                  Les deux os de l&apos;avant-bras : radius et ulna
                </Typography>
              </List.Item>
              <List.Item>
                <Typography>
                  Les os du carpe, composés de huit os courts comme le scaphoïde ou le trapèze. Ils
                  sont disposés en deux rangées de quatre os.
                </Typography>
              </List.Item>
            </List>
            <Figure
              imageName="figure1-fracturedupoignet.jpg"
              caption="Figure 1. Anatomie osseuse du poignet"
              currentOpTitle="Fracture du poignet"
            />
          </Typography>
        </MainPart>
        <MainPart title="Qu'est-ce qu'une fracture du poignet ?">
          <Typography>
            On parle de fracture du poignet quand l&apos;un (ou plusieurs) des os qui le composent
            est cassé. La fracture du poignet est la fracture la plus fréquente du membre supérieur.
            Elle concerne principalement l&apos;extrémité inférieure du radius, associé ou non à
            une fracture de l&apos;extrémité distale de l&apos;ulna (figure 1).
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les causes possibles d'une fracture du poignet ?">
          <Typography>
            La fracture du poignet survient schématiquement dans deux situations différentes,
            avec deux profils de patients également très différents :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                Chez les sujets âgés,
                {' '}
                <Typography variant="span">
                  Ici, ça sera principalement la femme qui va être concernée, du fait de
                  l&apos;ostéoporose secondaire à la ménopause. La fracture survient le plus
                  souvent suite à un traumatisme à basse énergie (chute de la hauteur de la
                  personne). On observe le plus fréquemment une fracture du radius,
                  associé ou non à une fracture de l&apos;extrémité distale de l&apos;ulna,
                  ne touchant pas ou peu l&apos;articulation.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Chez les sujets jeunes
                {' '}
                <Typography variant="span">
                  À l&apos;inverse, chez les patients jeunes, ce sont souvent les hommes qui
                  sont confrontés à cette pathologie. Elle fait suite à un traumatisme à
                  haute énergie (chute de grande hauteur, accident sur la voie publique,
                  accident de sport). Il s&apos;agit en général de fractures complexes qui
                  touchent souvent l&apos;articulation.
                </Typography>
              </Typography>
            </List.Item>
          </List>
        </MainPart>
        <MainPart title="Les symptômes d&apos;une fracture du poignet">
          <Typography>
            Lorsque le patient présente une fracture du poignet, il décrit le plus souvent un
            craquement, suivi d&apos;une impotence fonctionnelle complète du poignet après le
            traumatisme. Dans la majorité des cas, le poignet est gonflé et très douloureux
            quand le patient essaye de le bouger (figure 2). En cas de fracture du radius à
            bascule postérieure, on peut voir un aspect typique en « dos de fourchette » du
            poignet (figure 3).
          </Typography>
          <div className="flex flex-col items-start space-x-0 space-y-8 mdx:flex-row mdx:space-x-8 mdx:space-y-0">
            <Figure
              maxWidth="max-w-[250px]"
              imageName="figure2-fracturedupoignet.jpg"
              caption="Figure 2. Important gonflement et hématome du poignet dans les suites d&apos;une fracture du radius"
              currentOpTitle="Fracture du poignet"
            />
            <Figure
              maxWidth="max-w-[250px]"
              imageName="figure3-fracturedupoignet.jpg"
              caption="Figure 3. Aspect en « dos de fourchette du poignet » dans les suites d&apos;une fracture du radius"
              currentOpTitle="Fracture du poignet"
            />
          </div>
        </MainPart>
        <MainPart title="Quels examens pour le diagnostic de la fracture du poignet ?">
          <div className="flex flex-col">
            <Typography>
              En règle générale, les radiographies (poignet de face et de profil) sont suffisantes
              pour déceler la fracture. Les clichés radiographiques permettent de localiser
              son siège et d&apos;apprécier son déplacement (figure 4).
            </Typography>
            <Typography>
              Si le patient présente une fracture complexe et/ou articulaire du radius, alors
              le scanner est indiqué (figure 5).
            </Typography>
          </div>
          <div className="flex flex-col items-start space-x-0 space-y-8 mdx:flex-row mdx:space-x-8 mdx:space-y-0">
            <FigureDouble
              maxWidth="max-w-[300px]"
              height="h-[150px]"
              imageNameOne="figure4-fracturedupoignet.jpg"
              imageNameTwo="figure4bis-fracturedupoignet.jpg"
              caption="Figure 4. Fracture de l&apos;extrémité inférieure du radius déplacée, à bascule postérieure"
              currentOpTitle="Fracture du poignet"
            />
            <FigureDouble
              maxWidth="max-w-[300px]"
              height="h-[150px]"
              imageNameOne="figure5-fracturedupoignet.jpg"
              imageNameTwo="figure5bis-fracturedupoignet.jpg"
              caption="Figure 5. Scanner du poignet mettant en évidence une fracture articulaire du radius"
              currentOpTitle="Fracture du poignet"
            />
          </div>
        </MainPart>
        <MainPart title="Les traitements de la fracture du poignet">
          <Typography weight="bold">
            Le traitement orthopédique (non chirurgical)
            {' '}
            <Typography variant="span">
              est indiqué en cas de fracture non déplacée. Une immobilisation par une machette
              (figure 6) ou par une attelle thermoformée sur mesure est suffisante. La durée
              d&apos;immobilisation est de 45 jours. En outre, le patient fait l&apos;objet
              d&apos;une surveillance régulière. Des radiographies sont réalisées à J7, J14,
              J21 et J45, afin de dépister un éventuel déplacement secondaire.
            </Typography>
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure6-fracturedupoignet.jpg"
            caption="Figure 6. Manchette en résine pour fracture du poignet"
            currentOpTitle="Fracture du poignet"
          />
          <Typography weight="bold">
            Le traitement chirurgical,
            {' '}
            <Typography variant="span">
              quant à lui, est indiqué en cas de fracture déplacée. L&apos;objectif est de
              restaurer l&apos;anatomie, tout en évitant une immobilisation prolongée.
              L&apos;intervention est le plus souvent réalisée sous anesthésie loco-régionale
              (on endort que le bras) et en ambulatoire (pas d&apos;hospitalisation). Le
              chirurgien stabilise la fracture, soit avec des broches, soit avec une plaque
              ou même une combinaison des deux.
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Comment est choisi le matériel en cas de chirurgie ? ">
          <Typography>
            Le choix du matériel est fait en fonction de plusieurs critères : l&apos;âge du
            patient, sa profession, mais aussi la qualité de son os et le type de fracture.
          </Typography>
          <Typography weight="bold">
            La plaque
            {' '}
            <Typography variant="span">
              est le traitement le plus indiqué en cas d&apos;ostéoporose, de fracture
              comminutive (en plusieurs morceaux) et de fracture articulaire. La plaque
              verrouillée permet de réaliser une réduction anatomique du radius. Les vis
              utilisées se verrouillent dans la plaque, afin d&apos;éviter tout phénomène
              de « débricolage » et de déplacement secondaire de la fracture (figure 7).
              Le poignet est ensuite immobilisé pour une période de 3 à 6 semaines en fonction
              de la qualité de l&apos;os et du type de fracture. La plaque est en général
              retirée au bout de 6 à 12 mois pour limiter les lésions tendineuses secondaires
              (long fléchisseur du pouce).
            </Typography>
          </Typography>
          <Figure
            maxWidth="max-w-[200px]"
            imageName="figure7-fracturedupoignet.jpg"
            caption="Figure 7. Plaque antérieure de poignet verrouillée pour fracture du radius"
            currentOpTitle="Fracture du poignet"
          />
          <Typography weight="bold">
            Les broches
            {' '}
            <Typography variant="span">
              sont principalement utilisées chez les patients jeunes qui présentent une
              fracture simple, non comminutive à bascule postérieure. Trois à quatre
              broches sont mises en place par des petites incisions cutanées. Le poignet
              est ensuite immobilisé dans une machette pendant 45 jours. Elles seront
              retirées à J45 au bloc opérateur par le chirurgien, ou parfois en consultation.
            </Typography>
          </Typography>
          <Typography>
            Note : en cas de fracture articulaire déplacée de l&apos;extrémité inférieure du
            radius, l&apos;arthroscopie est le plus souvent indispensable. L&apos;arthroscope
            (caméra miniature) est introduit dans le poignet afin de restaurer la surface
            articulaire et de limiter la survenue d&apos;arthrose (cf. chapitre spécifique).
            La fracture est le plus souvent stabilisée par une plaque, des broches ou par la
            combinaison des deux.
          </Typography>
        </MainPart>
        <MainPart title="Les suites post-opératoires d&apos;une opération chirurgicale de la fracture du poignet">
          <Typography>
            Après une chirurgie pour une fracture du poignet, des soins locaux sont réalisés
            au niveau de la cicatrice en cas d&apos;attelle amovible. Les fils, quant à eux,
            sont retirés à J14. Quelle que soit l&apos;immobilisation choisie, le pouce et
            les doigts doivent être libres pour éviter toute raideur digitale.
          </Typography>
          <Typography>
            La rééducation débute dès l&apos;ablation de l&apos;immobilisation et des éventuelles
            broches. Sa durée est variable. La récupération des amplitudes du pignet se fait de
            manière progressive sur 3 à 6 mois.
          </Typography>
          <Typography>
            Afin de favoriser une récupération plus rapide et de limiter la survenue d&apos;une
            algoneurodystrophie en post-opératoire (syndrome douloureux local suite à un
            traumatisme et/ou une opération), de la vitamine C est prescrite pour une durée de
            trois semaines.
          </Typography>
        </MainPart>
        <MainPart title="Les complications possibles après l&apos;opération de la fracture du poignet ">
          <Typography>
            Une intervention chirurgicale présente toujours un risque de complications. Ces
            complications sont classées en deux catégories distinctes :
          </Typography>
          <Typography size="mdx" weight="bold">
            Les complications non spécifiques
            <Typography variant="span">, on retrouve dans cette catégorie :</Typography>
          </Typography>
          <List>
            <List.Item>
              <Typography>
                L&apos;infection du site opératoire pouvant nécessiter une intervention de nettoyage
                associé à la prise d&apos;antibiotique.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Un hématome pouvant nécessiter une évacuation en cas de menace cutanée ou de
                compression nerveuse.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Une algodystrophie. Son apparition est indépendante du type de chirurgie. Elle
                évolue en deux phases : phase chaude (main gonflée et douloureuse, avec
                transpiration) puis phase froide (prédominance de la raideur). Son évolution
                est souvent longue, entre 12 à 18 moins. Des séquelles sont possibles : douleur
                et/ou raideur des articulations et/ou du poignet et/ou de l&apos;épaule.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Les accidents liés à l&apos;anesthésie
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">
            Les complications spécifiques
            <Typography variant="span">, on peut citer :</Typography>
          </Typography>
          <List>
            <List.Item>
              <Typography>
                Une lésion des branches sensitives du nerf radial, lors de la pose ou bien,
                notamment, lors de l&apos;ablation des broches.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                La rupture tendineuse : elle peut survenir sur une fracture non opérée
                (les tendons frottent sur les bords de la fracture), après la mise en place
                des broches (frottement des tendons sur les broches) ou d&apos;une plaque
                (frottement des tendons sur les vis et sur la plaque). La chirurgie de
                reprise est nécessaire (figure 8).
              </Typography>
            </List.Item>
          </List>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure8-fracturedupoignet.jpg"
            caption="Figure 8. Rupture du long extenseur du pouce droit suite à un brochage de poignet pour fracture du radius"
            currentOpTitle="Fracture du poignet"
          />
          <List>
            <List.Item>
              <Typography>
                La perte de la réduction avec déplacement secondaire de la fracture.
                Elle est fréquemment observée en cas de fracture ostéoporotique traitée
                avec des broches.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Un conflit entre l&apos;extrémité distale de l&apos;ulna et les os du
                carpe (conflit ulno-carpien), secondaire à une impaction (rupture) du
                radius lors de la fracture. Ce conflit est responsable de douleurs en
                regard de l&apos;extrémité inférieure de l&apos;ulna (« en-dedans du poignet »).
                L&apos;infiltration post-opératoire, voire la résection (réduction) de
                l&apos;extrémité inférieure du radius (Wafer procedure) permet le plus
                souvent d&apos;améliorer les symptômes.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Une raideur articulaire et des douleurs résiduelles, qui peuvent
                survenir malgré un traitement bien conduit.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                De l&apos;arthrose en cas de fracture du radius. L&apos;arthroscopie
                permet de limiter sa survenue.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                La non-consolidation. Elle est exceptionnelle au niveau du radius. Elle peut
                survenir en cas de fracture de l&apos;extrémité inférieure de l&apos;ulna.
                En cas de présence d&apos;un volumineux fragment de styloïde ulnaire
                (partie basse de l&apos;ulna) associé à une gêne et une douleur du poignet
                lors de la prono-supination (mouvement qui permet la rotation d&apos;une partie
                de membre) secondaire à une instabilité radio-ulnaire distale, une stabilisation
                chirurgicale est nécessaire.
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="mains" />
  </Layout>
);

export default FractureDuPoignet;
